import React from "react"
import { graphql } from "gatsby"
import Slugger from "github-slugger"
import Layout from "../components/layout"
import TOC from "../components/TOC"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, headings } = markdownRemark
  const onHeadingClick = (headingValue) => {
    const headingEl = document.getElementById(Slugger.slug(headingValue));
    if (headingEl) {
      headingEl.scrollIntoView();
    }
  }
  return (
    <Layout>
          <h1 className='title' >{frontmatter.title}</h1>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <TOC headings={headings} onHeadingClick={onHeadingClick} />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
      headings {
        value
        depth
      }
    }
  }
`
