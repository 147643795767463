import React from "react"

const TOC = ({ headings, onHeadingClick }) => {
    return (
        <div className='TOC'>
            {
                headings
                    .filter((heading) => heading.depth < 4)
                    .map((heading, i) => (
                        <span key={i} className={`depth-${heading.depth}`} onClick={() => onHeadingClick(heading.value)} aria-hidden='true'>
                            {heading.value}
                        </span>
                    ))
            }
        </div>
    )
}

export default TOC